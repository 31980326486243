Login
<template>
  <div class="login">
    <!-- 登录框 -->
    <div class="login-box">
      <div class="login-panel">
        <header>
          <h2>伽利略项目登录</h2>
        </header>
        <div class="form-panel">
          <div class="form-main">
            <div class="form-items">
              <span>用户名</span>
              <input
                type="text"
                class="form-control"
                v-model="userName"
                @keyup.enter="login" />
            </div>
            <div class="form-items">
              <span>密码</span>
              <input
                type="password"
                class="form-control"
                v-model="passWord"
                @keyup.enter="login" />
            </div>
            <div class="form-items">
              <button @click="login" class="login-btn">登录</button>
              <el-button type="text" style="margin-left: 20px" @click="register"
                >注册账号</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 注册窗口 -->
    <el-dialog title="账号注册" :visible.sync="registerVis" width="600px">
      <div class="register_box" v-show="showReg">
        <el-form
          label-width="70px"
          :model="regForm"
          :rules="rules"
          ref="register"
          hide-required-asterisk>
          <el-form-item label="用户名" class="form_item" prop="username">
            <el-input v-model.trim="regForm.username"></el-input>
          </el-form-item>
          <el-form-item label="姓名" class="form_item" prop="name">
            <el-input v-model.trim="regForm.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" class="form_item" prop="password">
            <el-input v-model.trim="regForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" class="form_item" prop="confirmP">
            <el-input v-model.trim="regForm.confirmP" show-password></el-input>
          </el-form-item>
          <el-form-item label="手机号" class="form_item" prop="phone">
            <el-input v-model.trim="regForm.phone"></el-input>
          </el-form-item>
        </el-form>
        <div class="form_btn">
          <el-button type="primary" @click="submitForm" size="small"
            >立即注册</el-button
          >
          <el-button @click="registerVis = false" size="small">取消</el-button>
        </div>
      </div>
      <div class="complete_box" v-show="!showReg">
        <el-result icon="success" title="注册成功">
          <template slot="extra">
            <el-button type="text" @click="registerVis = false"
              >去登录</el-button
            >
          </template>
        </el-result>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "login",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.regForm.password) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    let valiPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (this.utils.isCellPhone(value)) {
        callback();
      } else {
        callback(new Error("输入格式有误"));
      }
    };
    return {
      userName: "",
      passWord: "",
      registerVis: false, // 注册
      regForm: {
        username: "",
        password: "",
        confirmP: "", // 确认密码
        name: "",
        phone: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", message: "用户名未输入" },
        ],
        name: [{ required: true, trigger: "blur", message: "姓名未输入" }],
        password: [{ required: true, trigger: "blur", message: "密码未输入" }],
        confirmP: [{ validator: validatePass, trigger: "blur" }],
        phone: [{ validator: valiPhone, trigger: "change" }],
      },
      showReg: true,
    };
  },
  created() {},
  methods: {
    register() {
      this.registerVis = true;
      Object.keys(this.regForm).forEach((key) => {
        this.regForm[key] = "";
      });
    },
    submitForm() {
      this.$refs.register.validate((valid) => {
        if (valid) {
          let paramObj = {
            username: this.regForm.username,
            password: this.$md5(this.regForm.password),
            name: this.regForm.name,
            phone: this.regForm.phone,
          };

          this.$api
            .registerFunc(paramObj)
            .then((res) => {
              // console.log(res)
              if (res.code == 0) {
                this.showReg = false;
              }
            })
            .catch((error) => {
              console.log(error);
              // this.$message.error('注册失败')
              this.$message.error("用户已注册");
            });
        }
      });
    },
    login() {
      if (this.userName == "" || this.passWord == "") {
        this.$message.warning("用户名或密码为空");
        return;
      }
      let paramObj = {
        username: this.userName,
        password: this.$md5(this.passWord),
      };
      api
        .login(paramObj)
        .then((res) => {
          if (res.code == 0) {
            this.utils.sessionSetStore("token", res.data.token);
            this.utils.sessionSetStore("userName", res.data.list.username);
            this.utils.sessionSetStore("userId", res.data.list.id);

            this.$router.push("/index_view");
            // this.$router.push('/brand_view')
            this.utils.localSetStore(
              "chromeExtension",
              res.data.token +
                "^_^" +
                res.data.username +
                "^_^" +
                res.data.list.id
            );
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #0a48d1 linear-gradient(-90deg, #0a48d1 0%, #1183fb 100%);

  .login-box {
    width: 600px;
    // height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -300px;
    padding: 0 40px;
    .login-panel {
      background: #fff;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;

      > header {
        padding: 20px;
        border-bottom: 1px #eee solid;
        > h2 {
          font-size: 16px;
          margin: 0;
          line-height: 32px;
          max-width: 83%;
        }
      }

      .form-panel {
        // background: rosybrown;
        min-height: 200px;
        padding: 20px 60px;

        .form-items {
          line-height: 30px;
          // background: saddlebrown;
          margin: 10px;
          margin-bottom: 20px;

          > span {
            display: inline-block;
            padding: 0 20px;
            font-size: 13px;
            width: 80px;
          }
          .form-control {
            width: 68%;
            height: 32px;
            padding: 5px 8px;
            border-radius: 3px;
            border: 1px solid #dcdcdc;
          }
          .login-btn {
            margin-top: 10px;
            margin-left: 100px;
            color: #fff;
            background-color: #1183fb;
            border-color: transparent;
            font-size: 13px;
            font-weight: 400;
            padding: 6px 12px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.register_box {
  padding: 0 20px;

  .form_item {
    text-align: center;
    padding: 0 100px;
  }
  .form_btn {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
